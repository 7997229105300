import $ from 'jquery';
import Backbone from 'js/backbone';
import AIS from 'js/AIS';
import FilterFormView from 'js/view/filter/FilterForm';
import ClassSelectTool from 'js/view/ClassSelectTool';
import ExportTool from 'js/view/ExportTool';
import RegionFilter from 'js/view/filter/global/RegionFilter';
// import AddressFilter from 'js/view/filter/global/AddressFilter';
// import SvObjectFilter from 'js/view/filter/global/SvObjectFilter';
import DateFilter from 'js/view/filter/global/DateFilter';
import OpticPoleFilter from 'js/view/filter/global/OpticPoleFilter';
import ValidYearFilter from 'js/view/filter/global/ValidYearFilter';


const FilterPanel = Backbone.View.extend({
  subscribeLoading() {
    this.filterForm.subscribeLoading();
  },
  render() {
    this.$el.empty().append($('#tmpl-filter-panel').html());
    const regionFilter = new RegionFilter({
      model: AIS.globalFilters,
      map: AIS.map,
    });
    this.$el.find('.region-filter').empty().append(regionFilter.render().el);

    // if (AIS.featureEnabled('address_filter')) {
    //   const addressFilter = new AddressFilter({
    //     model: AIS.globalFilters,
    //   });
    //   this.$el.find('.address-filter').empty().append(addressFilter.render().el);
    // }
    //
    // if (AIS.featureEnabled('sv_object_filter')) {
    //   const svObjectFilter = new SvObjectFilter({
    //     model: AIS.globalFilters,
    //   });
    //   this.$el.find('.sv-object-filter').empty().append(svObjectFilter.render().el);
    // }

    if (AIS.featureEnabled('date_filter')) {
      const dateFilter = new DateFilter({
        model: AIS.globalFilters,
      });
      this.$el.find('.date-filter').empty().append(dateFilter.render().el);
    }

    if (AIS.featureEnabled('valid_year_filter')) {
      const validYearFilter = new ValidYearFilter({
        model: AIS.globalFilters,
      });
      this.$el.find('.valid-year-filter').empty().append(validYearFilter.render().el);
    }

    this.filterForm = new FilterFormView({
      collection: this.options.workset,
      meta: AIS.meta,
    });
    this.$el.find('.filter-form-content').empty().append(this.filterForm.render().el);

    const layerControl = new ClassSelectTool({
      collection: AIS.meta,
      workset: this.options.workset,
    });
    this.$el.find('.filter-control').empty().append(layerControl.render().el);

    if (AIS.featureEnabled('export')) {
      const exportView = new ExportTool({
        model: AIS.globalFilters,
        collection: this.options.workset,
      });
      this.$el.find('.filter-export').empty().append(exportView.render().el);
    }

    if (AIS.featureEnabled('optic_pole_filter')) {
      const opticPoleFilter = new OpticPoleFilter({
        model: AIS.globalFilters,
      });
      this.$el.find('.optic-pole-filter').empty().append(opticPoleFilter.render().el);
    }

    return this;
  },
});

export default FilterPanel;
